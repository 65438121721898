<template>
  <div
      v-click-outside="
      () => {
        searchStore.hideSearchSuggestions()
      }
    "
  >
    <div>
      <!-- Search Query Input -->
      <input
          type="text"
          class="text-xs md:text-sm h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
          placeholder="Search For News..."
          v-model="localSearchQuery"
          @input="getInstantSearchResults"
      />
      <!-- Search Query Input Ends -->
      <!-- Search Suggestions Div -->
      <div
          :class="[
          'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
        ]"
          v-if="viewSearchSuggestionsList"
      >
        <!-- News Announcement Categories Suggestions -->
        <BaseInstantSearchSuggestionsList
            v-if="newsAnnouncementCategorySearchSuggestions.length > 0"
            :suggestions="newsAnnouncementCategorySearchSuggestions"
            highlightingFieldName="title"
            listTitle="Categories"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (selectedCategorySuggestion) =>
              searchSuggestedNewsAnnouncementCategory(
                selectedCategorySuggestion
              )
          "
        />
        <span class="w-full border-b border-a-neutral-dim"></span>
        <!-- News Announcement Tags Suggestions -->
        <BaseInstantSearchSuggestionsList
            v-if="newsAnnouncementTagSearchSuggestions.length > 0"
            :suggestions="newsAnnouncementTagSearchSuggestions"
            highlightingFieldName="title"
            listTitle="Tags"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (selectedTagSuggestion) =>
              searchSuggestedNewsAnnouncementTag(selectedTagSuggestion)
          "
        />
        <span class="w-full border-b border-a-neutral-dim"></span>
        <!-- News Announcement Suggestions -->
        <BaseInstantSearchSuggestionsList
            v-if="newsAnnouncementSearchSuggestions.length > 0"
            :suggestions="newsAnnouncementSearchSuggestions"
            highlightingFieldName="title"
            listTitle="News Announcements"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (selectedAnnouncementSuggestion) =>
              gotoSuggestedNewsAnnouncementPage(selectedAnnouncementSuggestion)
          "
        />
      </div>
      <!-- Search Suggestions Div Ends -->
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
const newsStore = useNews();
const searchStore = useSearch();
const route = useRoute();


const localSearchQuery = ref("");
const newsAnnouncementCategorySearchSuggestions = ref([]);
const newsAnnouncementTagSearchSuggestions = ref([]);
const newsAnnouncementSearchSuggestions = ref([]);

const emit = defineEmits([
  "searchBySelectedCategory",
]);

function searchSuggestedNewsAnnouncementCategory(category) {
  searchStore.hideSearchSuggestions();
  localSearchQuery.value = category.title;
  searchStore.setSearchQuery(category.title);
  searchStore.isSearching();
  emit("searchBySelectedCategory", category);
}
function searchSuggestedNewsAnnouncementTag(tag) {
  searchStore.hideSearchSuggestions();
  localSearchQuery.value = tag.title;
  searchStore.setSearchQuery(tag.title);
  searchStore.isSearching();
  emit("searchBySelectedTag", tag);
}

async function getInstantSearchResults() {
  if (
      localSearchQuery.value !== "" &&
      localSearchQuery.value.length > 1
  ) {
    searchStore.showSearchSuggestions();
    const results = await useMainNewsDataManager().getNewsInstantSearchResults(
        localSearchQuery.value
    );
    if (results) {
      newsAnnouncementCategorySearchSuggestions.value =
          results.news_announcement_categories_results;
      newsAnnouncementTagSearchSuggestions.value =
          results.news_announcement_categories_results;
      newsAnnouncementSearchSuggestions.value =
          results.news_announcement_results;
    } else {
      // noResultsFoundInSearchSuggestion.value = true;
    }
  }
}

function gotoSuggestedNewsAnnouncementPage(newsAnnouncement) {
  localSearchQuery.value = "";
  navigateTo({
    name: "news-announcements-slug",
    params: {
      slug: newsAnnouncement.slug,
    },
  });
}

const viewSearchSuggestionsList = computed(()=> {
  return (
      searchStore.is_showing_search_suggestions &&
      (newsAnnouncementCategorySearchSuggestions.value.length > 0 ||
          newsAnnouncementTagSearchSuggestions.value.length > 0 ||
          newsAnnouncementSearchSuggestions.value.length > 0)
  );
});

watch(()=>localSearchQuery.value,()=>{
  if (localSearchQuery.value == "") {
    searchStore.hideSearchSuggestions();
  }
  searchStore.setSearchQuery(localSearchQuery.value)
})

watch(()=>route.query, ()=>{
  localSearchQuery.value = searchStore.current_search_query;
})

onMounted(()=>{
  if (searchStore.current_search_query) {
    localSearchQuery.value = searchStore.current_search_query;
  } else {
    route.query.q != null
        ? (localSearchQuery.value = route.query.q as string)
        : (localSearchQuery.value = route.query.c as string);
  }
})
</script>

<style></style>
