<template>
  <div
      class="flex flex-col justify-center items-center w-full m-0 p-0 z-50 sticky top-0 bg-transparent"
  >
    <!-- Top Bar -->
    <div class="flex flex-none w-full h-20 pt-2 bg-white">
      <div class="flex w-full items-center max-w-screen-2xl mx-auto">
        <Icon
            @click="drawerStore.openDrawer()"
            name="streamline:interface-setting-menu-2-button-parallel-horizontal-lines-menu-navigation-staggered-three-hamburger"
            class="w-2/12 text-2xl mx-2"
        />
        <div class="flex flex-col pl-2 items-start justify-center w-6/12">
          <NuxtImg
              src="/images/main/logo.png"
              class="h-10 w-24"
              alt=""
          />
          <p class="text-xs mx-auto font-semibold tracking-widest">
            News
          </p>
        </div>
        <BaseButton
            margin-classes="mx-1"
            text-size-class="text-xs"
            is-outlined
            primary-color-class="a-primary"
            hovered-text-color-class="a-neutral-darkest"
            width-class="w-4/12"
            @click.native="
              gotoCreateNewNewsAnnouncement
            "
        >
          <Icon
              name="mdi:plus"
          />
          New Announcement
        </BaseButton>
      </div>
    </div>
      <!-- Top Bar Ends-->
    <!--    Search Bar -->
    <div class="max-w-screen w-full px-2 py-2 bg-white">
      <NewsInstantSearch/>
    </div>
    <!--    Search Bar Ends -->
      <!-- English News Bar-->
      <NewsTickerBar
          :barTitle="'Breaking News'"
          :newsTickers="newsTickers as NewsTicker[]"
          :property="'eng_details'"
          :swiperName="'english-news-swiper'"
      />
      <!-- English News Bar Ends-->
      <!-- Urdu News Bar-->
      <NewsTickerBar
          :barTitle="'تازہ خبریں'"
          :newsTickers="newsTickers as NewsTicker[]"
          :property="'urdu_details'"
          :isUrdu="true"
          :swiperName="'urdu-news-swiper'"
      />
      <!-- Urdu News Bar Ends-->
      <!-- Main News Alert -->
      <BaseAlert
          alignText="center"
          class="z-50"
      />
      <!-- Main News Alert Ends -->
    </div>
</template>

<script
    setup
    lang="ts"
>
import {useMainNewsDataManager} from "~/composables/useMainNewsDataManager";
import type {NewsTicker} from "~/types";
import {useAuthManager, useDrawer} from "#imports";

const drawerStore = useDrawer();

const showCategoriesDropdown = ref(false);
const newsTickers = await useMainNewsDataManager().getMainNewsTickers();

function viewNewsPost(newsPostId) {
  navigateTo("/news/posts/" + newsPostId);
}

function gotoNewsHome() {
  navigateTo("/news");
}

function gotoCreateNewNewsAnnouncement() {
  navigateTo({
    name: "dashboard-news-create-new",
  });
}

const isLoggedIn = computed(() => {
  return useAuthManager().isLoggedIn.value
})

</script>

<style></style>
