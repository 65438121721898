<template>
  <div
      class="flex flex-none w-full max-w-screen md:max-w-screen-2xl h-10 md:h-14 border-a-secondary select-none"
      :class="[isUrdu?'border-b-2':'border-t-2 border-b-2']"
  >
    <div
        class="flex w-full mx-auto justify-start items-center bg-white"
        :class="isUrdu ? 'flex-row-reverse font-urdu text-xl' : 'flex-row'"
    >
      <div
          class="flex flex-row items-center justify-center text-md font-bold text-a-secondary w-48 h-full min-h-full border-a-secondary"
          :class="[isUrdu?'border-l-2':'border-r-2']"
      >
        <Icon
            class="animate-pulse text-xl text-a-error-dark"
            name="mingcute:live-photo-fill"
            v-if="!isUrdu"
        />
        <p class="text-sm md:text-base">&nbsp;{{ barTitle }}&nbsp;</p>
        <Icon
            class="animate-pulse text-xl text-a-error-dark"
            name="mingcute:live-photo-fill"
            v-if="isUrdu"
        />
      </div>
      <div
          class="swiper newsTickerBarCarousel w-full h-10"
      >
        <div class="swiper-wrapper">
          <div
              v-for="newsTicker in newsTickers"
              :key="newsTicker.id"
              class="swiper-slide text-black flex items-center w-full"
              :class="[
              isUrdu
                ? 'flex-row-reverse pr-3 text-lg text-right tracking-wide'
                : 'flex-row pl-3 text-left',
            ]"
          >
            <p class="text-xs md:text-base mt-1.5 md:mt-2">{{ newsTicker[property] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
import type {NewsTicker} from "~/types";
import {Autoplay} from "swiper/modules";

const props = withDefaults(defineProps<{
  barTitle: string,
  newsTickers: NewsTicker[],
  property: string,
  isUrdu?: boolean,
  swiperName: string
}>(), {
  isUrdu: false
})

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.newsTickerBarCarousel', {
    modules: [Autoplay],
    direction: 'vertical',
    loop: true,
    autoplay: {
      delay: props.isUrdu ? 4000 : 3000,
    },
    speed: 500,
    slidesPerView: 1,
    centeredSlides: true,
    allowTouchMove: false,
    observer: true,
    effect: 'slide'
  })
})
</script>

<style>
</style>
